import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { appName } from "../../Helpers/Constants";

export const WelcomeSection = () => {
  const navigate = useNavigate();
  return (
    <div className="welcome-section">
      <div className="welcome">
        Welcome to <span>{appName}</span>
      </div>
      <div className="item">YOUR VISION IT'S OUR CREATION,</div>
      <div className="description">
        We provide innovative and reliable technology solutions to meet your
        business needs. Our services include web development, mobile app
        development, software development, IT consulting, and more. We're
        committed to staying at the forefront of emerging technologies and
        keeping you informed every step of the way. Let's work together to
        achieve your business objectives through technology.
      </div>
      <div className="btnContainer" onClick={() => navigate("/aboutus")}>
        <Button className="aboutBtn">About Us</Button>
      </div>
    </div>
  );
};
