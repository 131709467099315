import React from "react";

export const Asset = ({ width, height, fill }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={fill}
      viewBox="0 0 1024 1024"
      class="icon"
      version="1.1"
      id="_x32_"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M512.004 727.842c-96.978 0-175.876-78.896-175.876-175.876a8 8 0 0 1 7.994-7.994 8 8 0 0 1 7.994 7.994c0 88.156 71.73 159.886 159.886 159.886 88.146 0 159.876-71.73 159.876-159.886a8.01 8.01 0 0 1 7.994-7.994 7.988 7.988 0 0 1 7.994 7.994c0.004 96.98-78.878 175.876-175.862 175.876zM615.922 967.672H408.078a7.982 7.982 0 0 1-5.652-2.344l-31.978-31.976a7.988 7.988 0 0 1-2.342-5.652v-111.92a7.98 7.98 0 0 1 7.994-7.994h271.798a7.968 7.968 0 0 1 7.994 7.994v111.92a7.992 7.992 0 0 1-2.342 5.652l-31.976 31.976a7.99 7.99 0 0 1-5.652 2.344z m-204.534-15.99h201.224l27.292-27.292v-100.616H384.094v100.616l27.294 27.292z"
        fill=""
      />
      <path
        d="M360.112 871.738a7.988 7.988 0 0 1-7.978-7.588 7.994 7.994 0 0 1 7.556-8.4l303.792-15.988c4.466-0.282 8.182 3.17 8.4 7.558 0.218 4.418-3.152 8.18-7.556 8.414l-303.792 15.988c-0.142 0.016-0.282 0.016-0.422 0.016zM360.112 919.706a7.99 7.99 0 0 1-7.978-7.588 7.994 7.994 0 0 1 7.556-8.4l303.792-15.988c4.466-0.156 8.182 3.154 8.4 7.556 0.218 4.418-3.152 8.182-7.556 8.416l-303.792 15.988c-0.142 0.016-0.282 0.016-0.422 0.016zM512.004 695.866c-96.978 0-175.876-78.912-175.876-175.882 0-96.978 78.896-175.876 175.876-175.876 96.984 0 175.866 78.898 175.866 175.876 0 96.97-78.882 175.882-175.866 175.882z m0-335.77c-88.156 0-159.886 71.722-159.886 159.886s71.73 159.892 159.886 159.892c88.146 0 159.876-71.728 159.876-159.892s-71.73-159.886-159.876-159.886z"
        fill=""
      />
      <path
        d="M344.124 559.96a7.98 7.98 0 0 1-7.994-7.994v-31.984a7.99 7.99 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994v31.984a7.98 7.98 0 0 1-7.994 7.994zM679.876 559.96a7.988 7.988 0 0 1-7.994-7.994v-31.984a8 8 0 0 1 7.994-7.994 7.98 7.98 0 0 1 7.994 7.994v31.984a7.968 7.968 0 0 1-7.994 7.994zM567.956 718.316a7.988 7.988 0 0 1-7.994-7.992v-32.042a8.01 8.01 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994v32.042a7.97 7.97 0 0 1-7.994 7.992zM472.032 723.064a8 8 0 0 1-7.994-7.994v-32.008a7.98 7.98 0 0 1 7.994-7.996 7.98 7.98 0 0 1 7.996 7.996v32.008a8.002 8.002 0 0 1-7.996 7.994zM631.91 677.598a7.99 7.99 0 0 1-7.994-7.996v-32.164a8.01 8.01 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994v32.164a7.97 7.97 0 0 1-7.994 7.996zM408.078 691.93a7.98 7.98 0 0 1-7.996-7.994v-32.132a8 8 0 0 1 7.996-7.994 8 8 0 0 1 7.994 7.994v32.132a7.98 7.98 0 0 1-7.994 7.994z"
        fill=""
      />
      <path
        d="M647.898 823.774H376.1a8 8 0 0 1-7.994-7.994v-49.542c-80.192-50.136-127.908-135.856-127.908-230.258 0-149.884 121.928-271.814 271.806-271.814 149.884 0 271.796 121.928 271.796 271.814 0 94.402-47.714 180.122-127.908 230.258v49.542a7.988 7.988 0 0 1-7.994 7.994z m-263.804-15.988h255.81v-46.03a7.98 7.98 0 0 1 3.872-6.84c77.664-46.842 124.038-128.69 124.038-218.938 0-141.062-114.762-255.824-255.808-255.824-141.056 0-255.818 114.762-255.818 255.824 0 90.248 46.374 172.096 124.038 218.938a7.976 7.976 0 0 1 3.872 6.84v46.03z"
        fill=""
      />
      <path
        d="M535.978 599.934a8.01 8.01 0 0 1-7.994-7.994v-143.906a8 8 0 0 1 7.994-7.994 7.98 7.98 0 0 1 7.994 7.994v143.906a7.99 7.99 0 0 1-7.994 7.994zM504.008 599.934a8 8 0 0 1-7.994-7.994v-119.922c0-4.418 3.576-7.994 7.994-7.994s7.996 3.576 7.996 7.994v119.922a8 8 0 0 1-7.996 7.994z"
        fill=""
      />
      <path
        d="M472.032 503.994a7.988 7.988 0 0 1-6.4-3.202 8 8 0 0 1 1.608-11.188l63.962-47.964a7.974 7.974 0 0 1 11.18 1.6 7.988 7.988 0 0 1-1.594 11.188l-63.962 47.966a7.98 7.98 0 0 1-4.794 1.6zM567.956 599.934h-95.924a8 8 0 0 1-7.994-7.994 7.98 7.98 0 0 1 7.994-7.994h95.924a7.968 7.968 0 0 1 7.994 7.994 7.992 7.992 0 0 1-7.994 7.994z"
        fill=""
      />
      <path
        d="M512.004 999.648c-60.208 0-78.352-34.914-79.1-36.412a7.982 7.982 0 0 1 3.576-10.71c3.966-1.936 8.712-0.39 10.696 3.498 0.624 1.216 15.146 27.636 64.83 27.636 49.768 0 64.226-26.514 64.82-27.636 2.06-3.874 6.838-5.434 10.742-3.436s5.496 6.744 3.53 10.648c-0.752 1.498-18.894 36.412-79.094 36.412z"
        fill=""
      />
      <path
        d="M168.264 543.972H8.364a7.998 7.998 0 0 1-7.994-7.994 7.986 7.986 0 0 1 7.994-8.002h159.9a7.988 7.988 0 0 1 7.996 8.002 8 8 0 0 1-7.996 7.994z"
        fill=""
      />
      <path
        d="M855.75 543.972a7.988 7.988 0 0 1-7.994-7.994 8.01 8.01 0 0 1 7.994-7.994l159.886-0.008a7.996 7.996 0 0 1 0 15.996h-159.886z"
        fill=""
      />
      <path
        d="M75.83 795.792a7.976 7.976 0 0 1-6.916-3.996 7.992 7.992 0 0 1 2.918-10.93l138.496-79.944c3.794-2.216 8.712-0.906 10.914 2.936a7.978 7.978 0 0 1-2.92 10.914l-138.494 79.942a7.916 7.916 0 0 1-3.998 1.078z"
        fill=""
      />
      <path
        d="M809.72 372.104a7.936 7.936 0 0 1-6.902-3.998 7.996 7.996 0 0 1 2.904-10.922l138.434-79.942a7.994 7.994 0 0 1 10.93 2.928 7.978 7.978 0 0 1-2.936 10.922l-138.434 79.942a7.904 7.904 0 0 1-3.996 1.07z"
        fill=""
      />
      <path
        d="M683.904 246.272a8.09 8.09 0 0 1-3.998-1.07 7.994 7.994 0 0 1-2.904-10.922l79.912-138.448c2.186-3.826 7.088-5.114 10.898-2.928a7.976 7.976 0 0 1 2.936 10.922l-79.912 138.448a8.002 8.002 0 0 1-6.932 3.998z"
        fill=""
      />
      <path
        d="M512.05 200.196a7.99 7.99 0 0 1-7.996-7.994l-0.032-159.856a7.996 7.996 0 0 1 15.99 0l0.032 159.856a7.996 7.996 0 0 1-7.994 7.994z"
        fill=""
      />
      <path
        d="M340.172 246.24a7.956 7.956 0 0 1-6.916-3.998l-79.944-138.432a7.984 7.984 0 0 1 2.92-10.922c3.826-2.216 8.728-0.92 10.914 2.928l79.944 138.432a7.984 7.984 0 0 1-6.918 11.992z"
        fill=""
      />
      <path
        d="M948.184 795.762a8.028 8.028 0 0 1-3.996-1.076l-138.464-79.944a7.986 7.986 0 0 1-2.904-10.914c2.188-3.842 7.09-5.122 10.898-2.936l138.464 79.944a7.984 7.984 0 0 1 2.936 10.93 8.008 8.008 0 0 1-6.934 3.996z"
        fill=""
      />
      <path
        d="M214.326 372.042a8.008 8.008 0 0 1-3.998-1.07L71.896 291.06a7.986 7.986 0 0 1-2.92-10.922 8.01 8.01 0 0 1 10.914-2.928l138.432 79.912a7.984 7.984 0 0 1 2.92 10.922 7.98 7.98 0 0 1-6.916 3.998z"
        fill=""
      />
    </svg>
  );
};
