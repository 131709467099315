import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { appName } from "../../Helpers/Constants";

export const AboutUsSection = () => {
  const navigate = useNavigate();
  const outTeam = () => {
    navigate("/aboutus");
  };

  const services = () => {
    navigate("/services");
  };

  return (
    <div className="aboutSection">
      <div className="rondConteiner">
        <div className="item">
          <div className="title">{appName}</div>{" "}
          <div className="des">
            {" "}
            YOUR VISION
            <br />
            IT'S OUR CREATION
            <br /> CUSTOM APPS THAT DELIVER RESULTS
          </div>
        </div>
      </div>
      <div className="leftSider">
        {" "}
        <div className="title">
          Our <br /> Team
        </div>
        <div className="buttonConLeft" onClick={outTeam}>
          <Button>Learn more</Button>
        </div>
      </div>
      <div className="rightSider">
        <div className="title">
          {" "}
          Our <br /> Services
        </div>
        <div className="buttonCon" onClick={services}>
          <Button>Learn more</Button>
        </div>{" "}
      </div>
    </div>
  );
};
