import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ServicesHeader } from "../../Assets/Images";
import { appName } from "../Helpers/Constants";
import { LoadingComponent } from "../Layout/LoadingComponent";
// import { QAservices } from "./QAservices";
import "./Services.scss";

export const Services = () => {
  const state = useLocation()?.state;

  useEffect(() => {
    if (state) {
      const section = document.querySelector(`#${state}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        top: 160,
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [state]);

  return (
    <div id="headerdEV" className="servicesPage">
      {!ServicesHeader && <LoadingComponent />}

      <div className="headerdEV">
        <img
          width="100%"
          height={350}
          src={ServicesHeader}
          alt="servicesHeader"
          className="devHeader"
        />
        <div className="content">
          <div className="title">Web Development</div>
          <div className="des">
            Our expert web development team specializes in creating custom
            websites that are tailored to meet the specific needs of our
            clients. We use the latest technologies and industry best practices
            to ensure that our websites are fast, secure, and user-friendly.
          </div>
        </div>
      </div>
      <div style={{ padding: 40 }}>
        <div className="itemDev">
          <div className="itemContainer">
            Custom Website Development, We can create a custom website from
            scratch that meets your unique requirements and specifications. Our
            team works closely with you to understand your business goals,
            target audience, and branding guidelines to create a website that
            accurately represents your business and engages your customers.
          </div>
          <div className="itemContainer">
            Content Management System (CMS) Development: If you need a website
            that requires frequent updates and content changes, we can create a
            custom CMS that makes it easy for you to manage your website's
            content. Our CMS solutions are user-friendly, scalable, and can be
            customized to meet your specific needs.
          </div>
          <div className="itemContainer">
            E-commerce Development: We can create an online store that is easy
            to use, secure, and reliable. Our e-commerce solutions include
            features such as shopping carts, payment gateway integration, and
            product management.
          </div>
          <div className="itemContainer">
            Website Optimization: Our web development team can optimize your
            website to improve its speed, search engine rankings, and user
            engagement. We conduct a thorough website audit to identify areas
            that need improvement and implement changes to enhance website
            performance.
          </div>
          <div className="itemContainer">
            Maintenance and Support: We offer ongoing maintenance and support
            services to ensure that your website is up-to-date, secure, and
            functioning properly. Our support channels include email, phone, or
            live chat.
          </div>
          <div className="itemContainer">
            At {appName}, we are committed to providing high-quality web
            development services that help our clients achieve their business
            goals. Contact us today to learn more about how we can help you
            create a website that stands out from the competition.
          </div>
        </div>
      </div>
      <div className="itemWeb" id="Mobile">
        <div className="BG">
          <div className="headerMobile">
            <div className="content">
              <div className="title">Mobile App Development</div>
              <div className="des">
                At {appName}, we offer comprehensive mobile app development
                services to help you bring your app idea to life. Our team of
                experienced developers can help you build custom mobile apps for
                iOS, Android, and hybrid platforms that are tailored to your
                specific needs
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: 40 }}>
          <div className="itemMobile">
            <div className="itemContainer">
              Our mobile app development process is designed to ensure that we
              deliver high-quality apps that meet your expectations. We begin
              with a thorough requirements gathering process to understand your
              needs and objectives. From there, we move on to designing
              wireframes and mockups, which we review with you to ensure that
              we're on the same page. Once the design is finalized, our
              developers get to work on building the app, using the latest
              technologies and development tools.
            </div>
            <div className="itemContainer">
              We also offer customization options to ensure that your app is
              exactly what you need. Our team of designers can help with UI/UX
              design to ensure that your app is user-friendly and easy to
              navigate. We can also help with data storage and integrations with
              third-party services to make sure that your app has all the
              features you need to succeed.
            </div>
            <div className="itemContainer">
              At {appName}, we have experience with a variety of programming
              languages, frameworks, and development environments. We use the
              latest tools and technologies, such as React Native, Flutter,
              Xcode, and Android Studio, to deliver high-quality apps that meet
              your needs and exceed your expectations.
            </div>
            <div className="itemContainer">
              We have a proven track record of delivering successful mobile app
              development projects for clients across various industries. Our
              portfolio includes a range of apps, from gaming and entertainment
              apps to enterprise apps designed for business use. We pride
              ourselves on our attention to detail and commitment to delivering
              high-quality work on time and within budget.
            </div>
          </div>
        </div>
      </div>
      {/* <div className="itemSoftware" id="Software">
        <div className="BGSoftware">
          <div className="headerMobile"></div>
        </div>
        <div className="contentSoftwareDev">
          <div style={{ padding: 40 }}>
            <div className="itemMobile">
              <div className="itemContainer">
                <div className="title">Discovery and Planning</div>
                <div className="des">
                  The first step in custom software development is to understand
                  the client's needs, requirements, and objectives. Our team
                  will work closely with you to determine the scope of the
                  project, identify potential roadblocks, and create a detailed
                  plan for development.
                </div>
              </div>
              <div className="itemContainer">
                <div className="title">Design and Development</div>
                <div className="des">
                  Once we have a clear understanding of your requirements, we
                  will start designing and developing your custom software
                  solution. Our team will use the latest programming languages,
                  frameworks, and tools to build a solution that meets your
                  specific needs.
                </div>
              </div>
              <div className="itemContainer">
                <div className="title">Quality Assurance</div>
                <div className="des">
                  Our team will rigorously test your custom software solution to
                  ensure that it is bug-free, stable, and performs as intended.
                  We will use a variety of testing methodologies and tools to
                  identify and fix any issues before the solution is deployed.
                </div>
              </div>
              <div className="itemContainer">
                <div className="title">Deployment and Implementation</div>
                <div className="des">
                  After the solution has been tested and approved, we will
                  deploy it to the production environment and assist with the
                  implementation process. Our team will ensure that your
                  solution is properly configured and integrated with your
                  existing systems.
                </div>
              </div>
              <div className="itemContainer">
                <div className="title">Maintenance and Support</div>
                <div className="des">
                  We provide ongoing maintenance and support services to ensure
                  that your custom software solution remains up to date, secure,
                  and optimized for performance. We offer different levels of
                  support packages that are tailored to your needs and budget.
                </div>
              </div>
              <div className="itemContainer">
                <div className="title">Communication and Collaboration</div>
                <div className="des">
                  Throughout the custom software development process, we
                  prioritize communication and collaboration with our clients.
                  We provide regular updates on the progress of the project, and
                  welcome feedback and input from our clients at every stage of
                  development.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="itemWeb" id="Consulting">
        <div className="BG">
          <div className="headerConsulting">
            <div className="content">
              <div className="title">IT Consulting</div>
              <div className="des">
                At {appName} software development consulting services are
                designed to help businesses make informed decisions about their
                software development projects and implement best practices to
                improve the quality and efficiency of their development process.
                Contact us today to learn more about how we can help your
                business succeed.
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: 40 }}>
          <div className="itemConsulting">
            <div className="itemContainer">
              Our software development consulting services leverage our team's
              technical expertise to help businesses make informed decisions
              about their software development projects. We have experience
              working with a range of programming languages, frameworks, and
              development methodologies, and can provide guidance on the best
              approach for your specific needs.
            </div>
            <div className="itemContainer">
              Our team has experience working with a range of software
              development methodologies, including Agile, Scrum, Waterfall, and
              DevOps. We can help businesses choose the methodology that is best
              suited to their project, and provide guidance on how to implement
              it effectively.
            </div>
            <div className="itemContainer">
              Our software development consulting services can help businesses
              design software architecture that is scalable, flexible, and
              aligned with their business goals. We can provide guidance on how
              to design software that is easy to maintain, upgrade, and
              integrate with other systems.
            </div>
            <div className="itemContainer">
              Our software development consulting services can help businesses
              identify and prioritize their software development requirements.
              We can work with stakeholders to understand their needs and
              develop a clear set of requirements that can guide the development
              process.
            </div>
            <div className="itemContainer">
              Our software development consulting services can help businesses
              manage their software development projects from start to finish.
              We can provide guidance on how to create project plans, manage
              resources, and monitor progress to ensure that projects are
              completed on time and within budget.
            </div>
            <div className="itemContainer">
              Our software development consulting services can help businesses
              implement best practices for software development, such as version
              control, code review, and continuous integration. We can provide
              guidance on how to implement these practices effectively to
              improve the quality of your software development process.
            </div>
          </div>
        </div>
      </div> */}
      {/* <QAservices /> */}
    </div>
  );
};
