import { useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderPro, ShowMobDes } from "../../Assets/Images";
import { appName } from "../Helpers/Constants";
import { useLoadingImage } from "../Helpers/LoadingHelper";
import { LoadingComponent } from "../Layout/LoadingComponent";
import "./products.scss";

export const QRcodeProduct = () => {
  const { backgroundLoaded } = useLoadingImage(HeaderPro);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="qrCodeProduct">
      {!backgroundLoaded && <LoadingComponent />}
      <div className="headerImgContainer">
        <Link to={"https://demo-qr-code-menu.netlify.app/"} target={"_blank"}>
          <img src={HeaderPro} alt="HeaderPro" width="100%" />
        </Link>
      </div>
      <div className="content">
        <div className="title">
          Menu and{" "}
          <Link
            to={"https://demo-qr-code-menu.netlify.app/admin"}
            target={"_blank"}
            style={{ color: "#fff" }}
            className="textUnderline"
          >
            admin dashboard
          </Link>{" "}
          to update menu in real time
        </div>
        <div className="subTitile">
          Login with username: 'admin' and password: '123'
        </div>
        <div className="des">
          We offer a custom dashboard with user role which allow the admin to
          set up the restaurant or cafe menu in different languages and update
          or create new menu item in real time it have options of a daily menu
          which can be updated in real time by the admin, with our product your
          costumers will have quick access to you menu.
        </div>
        <div className="des">
          Feel free to contact us to get a free demo and presentation about our
          product !
        </div>
      </div>
      <div className="productImgContainer">
        <Link
          to={"https://demo-qr-code-menu.netlify.app/admin"}
          target={"_blank"}
        >
          <img src={ShowMobDes} alt="ShowMobDes" width="100%" height="100%" />
        </Link>
      </div>
      <div className="decriptionForApp">
        <div className="title">
          Your Ultimate Restaurant Menu Management Solution!
        </div>
        <div className="DES">
          Are you a restaurant owner looking to streamline your menu management
          process and provide an exceptional dining experience for your
          customers? Look no further! {appName} have the all-in-one solution
          that combines a QR code restaurant menu page and an intuitive admin
          dashboard, empowering you to take full control of your restaurant's
          menu like never before.
        </div>
        <div className="DES">
          Say goodbye to bulky and outdated physical menus. Your restaurant's
          menu is accessible to customers with a simple scan of a QR code.
          Customers can conveniently view your menu on their smartphones.
        </div>
        <div className="DES">
          Cater to a diverse clientele effortlessly. Our app allows you to add,
          edit, and delete multiple languages for your menu, making it
          accessible and user-friendly for international guests.
        </div>
        <div className="DES">
          Manage your menu with ease through our user-friendly admin dashboard.
          No technical expertise required! Update your offerings, adjust prices,
          and showcase daily specials in real-time.
        </div>
        <div className="DES">
          With a few clicks from your phone, you can add new items, modify
          existing dishes, or remove discontinued ones. Keep your menu
          up-to-date, and impress your customers with fresh and exciting
          options.
        </div>
      </div>
      <div className="DESBG">
        Save time and effort with our easy-to-use app. Say goodbye to manual
        updates and printing costs.
      </div>
    </div>
  );
};
