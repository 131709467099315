import React from "react";

export const Team = ({ width, height, fill }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={fill}
      version="1.1"
      id="_x32_"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
    >
      <style type="text/css"></style>
      <g>
        <path
          class="st0"
          d="M485.08,404.527h2.564l10.002-117.716L512,256.324l-4.436-65.503c1.08-13.604-10.862-21.261-18.3-24.398
		l-13.544,33.481l-3.66-28.927l2.26-1.788l-0.607-7.868l5.583-1.966c-0.574-0.809-0.928-1.586-0.928-2.252c0-1.838,0-4.141,0-7.278
		c2.226-2.622,3.913-7.944,4.908-13.966c1.4-0.54,2.311-1.349,3.509-7.682c0.826-4.47-0.49-7.228-0.793-7.228
		c0.556-4.057,1.315-12.077-0.674-16.1c0,0,1.366-2.614,1.366-6.097c-5.735,0.868-24.053-11.614-29.518-1.451
		c-7.944-0.878-12.667,11.006-9.075,23.648c0,0-4.452-0.219-2.665,6.384c1.653,6.216,3.003,7.632,5.33,8.526
		c1.012,6.022,2.682,11.344,4.909,13.966c0,3.138,0,5.44,0,7.278c0,0.396-0.118,0.818-0.32,1.274l7.219,2.555l-0.641,8.256
		l2.26,1.788l-3.761,29.838l-17.036-33.903c-2.412,0.911-5.448,2.125-8.568,3.661l-5.735,42.64l-2.48-36.745
		c1.4-17.71-15.332-27.173-24.119-30.454c-0.54-0.202-1.113-0.464-1.721-0.793l-14.101,34.881l-3.98-31.457l2.463-1.957
		l-0.675-8.543l6.578-2.32c-0.034-0.168-0.068-0.329-0.068-0.489c0-2.235,0-5.035,0-8.872c2.699-3.196,4.757-9.69,5.988-17.035
		c1.704-0.658,2.817-1.653,4.267-9.37c1.03-5.456-0.573-8.813-0.978-8.813c0.691-4.95,1.636-14.733-0.81-19.642
		c0,0,1.67-3.188,1.67-7.438c-7,1.054-29.35-14.169-36.028-1.771c-9.665-1.071-15.433,13.435-11.048,28.851
		c0,0-5.431-0.278-3.256,7.793c2.024,7.573,3.66,9.302,6.51,10.39c1.232,7.346,3.273,13.839,5.988,17.035c0,3.382,0,5.946,0,8.046
		l9.057,3.214l-0.691,8.964l2.446,1.957l-4.1,32.444l-17.727-35.294c-0.168,0.076-0.337,0.152-0.489,0.22
		c-4.588,1.704-14.186,5.094-22.012,10.441l-7.809,58.073l-3.609-53.384v-0.008c0.135-1.687,0.068-3.315-0.152-4.866
		c-2.21-17.179-23.243-26.768-33.076-30.529l-19.633,41.72l-4.858-38.44l2.986-2.378l-0.844-10.946l8.096-2.868
		c0-2.597,0-5.844,0-10.272c3.171-3.744,5.583-11.352,7.017-19.97c3.34-1.257,5.246-3.29,7.641-12.17
		c2.53-9.454-3.829-9.125-3.829-9.125c5.144-18.073-1.619-35.05-12.954-33.802c-7.826-14.522-34.004,3.314-42.201,2.074
		c0,4.976,1.957,8.712,1.957,8.712c-2.851,5.744-1.754,17.222-0.945,23.016c-0.472-0.008-6.224,0.059-3.778,9.125
		c2.396,8.88,4.284,10.913,7.641,12.17c1.434,8.618,3.828,16.226,7,19.97c0,4.428,0,7.675,0,10.272l9.563,3.382l-0.809,10.432
		l3.002,2.378l-5.178,40.937l-20.814-44.217c-10.745,4.09-34.814,15.138-33.228,35.396l-2.699,39.975l-6.004-44.656
		c-7.826-5.346-17.44-8.737-22.012-10.441c-0.168-0.068-0.338-0.144-0.506-0.22l-17.727,35.294l-4.1-32.444l2.446-1.957
		l-0.692-8.964l9.057-3.214c0-2.1,0-4.664,0-8.046c2.699-3.196,4.773-9.69,5.988-17.035c2.85-1.088,4.486-2.817,6.527-10.39
		c2.159-8.071-3.272-7.793-3.272-7.793c4.385-15.416-1.382-29.922-11.048-28.851c-6.679-12.398-29.028,2.825-36.028,1.771
		c0,4.25,1.67,7.438,1.67,7.438c-2.428,4.909-1.501,14.692-0.809,19.642c-0.405,0-2.008,3.357-0.978,8.813
		c1.467,7.717,2.563,8.712,4.284,9.37c1.214,7.346,3.255,13.839,5.971,17.035c0,3.838,0,6.638,0,8.872
		c0,0.16-0.034,0.32-0.051,0.489l6.562,2.32l-0.674,8.543l2.462,1.957l-3.964,31.457l-14.117-34.881
		c-0.608,0.329-1.198,0.591-1.721,0.793c-8.787,3.281-25.503,12.743-24.103,30.454l-2.496,36.821l-5.752-42.716
		c-3.103-1.536-6.139-2.75-8.551-3.661L51.58,200.823l-3.762-29.847l2.26-1.788l-0.641-8.256l7.236-2.555
		c-0.219-0.456-0.338-0.878-0.338-1.274c0-1.838,0-4.141,0-7.278c2.21-2.622,3.914-7.944,4.909-13.966
		c2.345-0.894,3.677-2.31,5.346-8.526c1.771-6.603-2.665-6.384-2.665-6.384c3.593-12.642-1.147-24.525-9.074-23.648
		c-5.466-10.163-23.8,2.319-29.535,1.451c0,3.483,1.366,6.097,1.366,6.097c-1.99,4.022-1.23,12.043-0.657,16.1
		c-0.32,0-1.636,2.758-0.793,7.228c1.181,6.334,2.092,7.143,3.492,7.682c1.012,6.022,2.682,11.344,4.908,13.966
		c0,3.138,0,5.44,0,7.278c0,0.666-0.337,1.442-0.91,2.252l5.566,1.966l-0.624,7.868l2.26,1.788l-3.642,28.911l-13.545-33.464
		c-7.438,3.138-19.38,10.795-18.318,24.398L0,256.324l14.354,30.487l10.019,117.716h2.563l-0.05,1.805l-15.299,17.525l5.094,6.367
		l30.597-10.213v-15.484h1.501l0.22-87.363l17.407,87.363h4.115l0.168,1.113l-2.968,15.265l6.275,9.124l12.076-0.911l4.739-9.799
		l-1.434-14.793h1.434l-7.168-117.716l5.347-11.351l8.518,18.098l12.212,143.598h3.12l-0.051,2.202l-18.654,21.37l6.224,7.776
		l37.31-12.474v-18.874h1.821l0.287-106.574l21.236,106.574h5.01l0.22,1.358l-3.626,18.63l7.64,11.123l14.742-1.121l5.785-11.951
		l-1.754-18.04h1.754l-8.754-143.598l10.441-22.172l12.667,26.936v154.696h3.829l-0.017,0.81l-23.294,26.7l7.759,9.707
		l46.604-15.576v-21.641l14.623-111.314l14.64,111.314h1.502v21.641l46.586,15.576l7.776-9.707l-23.31-26.7l-0.018-0.81h2.345
		V298.323l14.016-29.804l11.807,25.039l-8.754,143.598h1.754l-1.754,18.04l5.768,11.951l14.742,1.121l7.658-11.123l-3.644-18.63
		l0.22-1.358h5.01l21.252-106.574l0.27,106.574h1.821v18.874l37.327,12.474l6.207-7.776l-18.654-21.37l-0.051-2.202h3.12
		l12.212-143.598l8.518-18.098l5.347,11.351l-7.168,117.716h1.434l-1.434,14.793l4.739,9.799l12.076,0.911l6.275-9.124
		l-2.968-15.265l0.168-1.113h4.116l17.406-87.363l0.236,87.363h1.502v15.484l30.58,10.213l5.094-6.367l-15.299-17.525
		L485.08,404.527z"
        />
      </g>
    </svg>
  );
};
