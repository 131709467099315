import React from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../Assets/Images";
import { appName } from "../Helpers/Constants";

const Footer = ({ darkMode }) => {
  const navigate = useNavigate();

  return (
    <div className={darkMode ? "footer" : "footer-light"}>
      <div className="contanier">
        <div>
          <a href="https://wa.me/32487290915">WhatsApp: +32487290915</a>
        </div>
        <div>
          <a href="mailto:tech.bm.solutions@gmail.com">
            <p>tech.bm.solutions@gmail.com</p>
          </a>
        </div>
      </div>
      <div className="contanierItem">
        <div className="row">
          <a className="footer-nav" href="#" onClick={() => navigate("/")}>
            Home
          </a>
          <a className="footer-nav" href="#" onClick={() => navigate("/")}>
            ABOUT US
          </a>
          <a className="footer-nav" href="#" onClick={() => navigate("/")}>
            SERVICES
          </a>
        </div>
        <div className="row">
          <a className="footer-nav" href="#" onClick={() => navigate("/")}>
            PRODUCTS
          </a>
          {/* <a className="footer-nav" href="#" onClick={() => navigate("/")}>
            PORTFOLIO
          </a> */}
          <a className="footer-nav" href="#" onClick={() => navigate("/")}>
            CONTACTS
          </a>
        </div>
      </div>
      <div className="contanier">
        <img src={Logo} alt="logo" width={150} />
        <p className="pt-3 text-center">
          Copyright&copy;
          {new Date().getFullYear()}&nbsp;{appName} | All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
