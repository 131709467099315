import React from "react";
import { useNavigate } from "react-router-dom";

export const ServicesSection = () => {
  const navigate = useNavigate();

  const onNavigate = (state) => {
    navigate("/services", { state });
  };

  return (
    <div className="cardSection">
      <div className="firstContainer">
        <div className="item" onClick={() => onNavigate("headerdEV")}>
          <div className="title">Web Development</div>
          <div className="line"></div>
          <div className="des">
            Designing and developing websites for clients, including front-end
            and back-end development, website maintenance, and website
            optimization.{" "}
          </div>
        </div>
        <div className="item" onClick={() => onNavigate("Mobile")}>
          <div className="title">Mobile App Development</div>
          <div className="line"></div>
          <div className="des">
            Designing and developing mobile applications for iOS and Android
            platforms, including user experience design, app development,
            testing, and deployment.{" "}
          </div>
        </div>
      </div>
      <div className="secondContainer">
        {/* <div className="item">
          <div className="title">Cloud Computing Services</div>
          <div className="line"></div>
          <div className="des">
          Offering cloud computing services such as infrastructure as a
          service (IaaS), platform as a service (PaaS), and software as a
          service (SaaS).
          </div>
        </div> */}
        {/* <div className="item">
          <div className="title">Data Analytics and Business Intelligence</div>
          <div className="line"></div>
          <div className="des">
          Providing data analytics and business intelligence solutions,
          including data collection, data analysis, data visualization, and
          reporting.{" "}
          </div>
        </div> */}
        <div className="item" onClick={() => onNavigate("Software")}>
          <div className="title">Software Development</div>
          <div className="line"></div>
          <div className="des">
            {" "}
            Developing customized software applications for clients, including
            web-based applications, desktop applications, and enterprise
            software solutions.{" "}
          </div>
        </div>
        <div className="item" onClick={() => onNavigate("Consulting")}>
          <div className="title">IT Consulting</div>
          <div className="line"></div>
          <div className="des">
            Providing consulting services to clients to help them with their IT
            strategy, technology selection, project management, and
            implementation.{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
