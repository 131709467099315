import React, { useEffect, useState } from "react";
import "./products.scss";
import { DonCarlos, Pronto, QrCode } from "../../Assets/Images";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { appName } from "../Helpers/Constants";
import { LoadingComponent } from "../Layout/LoadingComponent";

export const Products = () => {
  const navigate = useNavigate();

  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = DonCarlos;

    const handleBackgroundLoaded = () => {
      setBackgroundLoaded(true);
    };

    const handleBackgroundError = () => {
      setBackgroundLoaded(false);
    };

    backgroundImage.addEventListener("load", handleBackgroundLoaded);
    backgroundImage.addEventListener("error", handleBackgroundError);

    return () => {
      backgroundImage.removeEventListener("load", handleBackgroundLoaded);
      backgroundImage.removeEventListener("error", handleBackgroundError);
    };
  }, []);

  return (
    <div className="products">
      {!backgroundLoaded && <LoadingComponent />}
      <div className="Header">
        <div className="productsHeader">Our Products</div>
      </div>
      <div className="restorantSection">
        <div className="websiteSection">
          <div className="imgConatiner">
            <a href="https://www.ristorantedoncarlos.com/" target="_blank">
              <img
                src={DonCarlos}
                alt="Don Carls"
                width={"100%"}
                height={"100%"}
              />
            </a>
          </div>
          <div className="content">
            <div className="title">Restaurant Website</div>
            <div className="content">
              Our team of skilled designers and developers will create a
              visually stunning website that captures the essence and style of
              your restaurant website. From elegant color schemes to engaging
              layouts, we ensure that the website reflects the ambiance and
              character of the establishment with custom features, leaving a
              memorable impression on visitors.
            </div>
            <div className="btnContainer" onClick={() => navigate("/contacts")}>
              <Button className="aboutBtn">Contact Us</Button>
            </div>
          </div>
        </div>
        <div className="websiteSection">
          <div className="content">
            <div className="title">Menu QR Code</div>
            <div className="content">
              Our QR code menu page allows you to show your restaurant menu in a
              dynamic and interactive format and different languages. Customers
              can easily scan the QR code using their smartphones to access the
              menu, which will be displayed in an appealing and user-friendly
              layout.
            </div>
            <div
              className="btnContainer"
              onClick={() => navigate("/products/qrcode")}
            >
              <Button className="aboutBtn">Learn More</Button>
            </div>
          </div>
          <div className="imgConatiner" style={{ padding: 50 }}>
            <a href="https://doncarlos-menu.netlify.app/" target="_blank">
              <img src={QrCode} alt="QRcode" width={"100%"} height={"100%"} />
            </a>
          </div>
        </div>
        <div className="websiteSection">
          <div className="imgConatiner">
            <a href="https://pronto-intervento24.com/" target="_blank">
              <img
                src={Pronto}
                alt="Pronto Intervento"
                width={"100%"}
                height={"100%"}
              />
            </a>
          </div>
          <div className="content">
            <div className="title">Bussines Website</div>
            <div className="content">
              {appName} offers stunning, custom-designed business websites that
              are sure to impress your clients. We understand the importance of
              a strong online presence for businesses, and our websites are
              designed to captivate visitors and drive growth.
            </div>
            <div className="btnContainer" onClick={() => navigate("/contacts")}>
              <Button className="aboutBtn">Contact Us</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
