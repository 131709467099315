import React, { useEffect } from "react";
import { AboutUsSection } from "./components/AboutUsSection";
import { WelcomeSection } from "./components/WelcomeSection";
import { LandingSection } from "./components/LandingSection";
import { ServicesSection } from "./components/ServicesSection";
import { ProductSection } from "./components/ProductSection";
import { SectionImg } from "../../Assets/Images";
// import { LoadingComponent } from "../Layout/LoadingComponent";
import { Contact } from "../Contact/Contact";
// import { useLoadingImage } from "../Helpers/LoadingHelper";
import "./HomePage.scss";

export const HomePage = () => {
  // const { backgroundLoaded } = useLoadingImage(SectionImg);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="homePage">
      {/* {!backgroundLoaded && <LoadingComponent />} */}
      <LandingSection imgRef={SectionImg} />
      <AboutUsSection />
      <WelcomeSection />
      <ServicesSection />
      <ProductSection />
      <Contact header={true} />
    </div>
  );
};
