import React, { useEffect } from "react";
import { Asset } from "../../Assets/Icons/Asset";
import { Cyber } from "../../Assets/Icons/Cyber";
import { Design } from "../../Assets/Icons/Design";
import { Developers } from "../../Assets/Icons/Developers";
import { Team } from "../../Assets/Icons/Team";
import { Work } from "../../Assets/Icons/Work";
import { appName } from "../Helpers/Constants";
import "./AboutUS.scss";

export const AboutUS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="AboutUS">
      <div className="header">
        <div className="appName">"{appName}"</div>
      </div>
      <div className="content">
        <div className="left">
          <div className="item">
            <div className="iconContainer">
              <Team width={70} height={70} fill="rgb(48, 48, 46)" />
            </div>
            At {appName}, we have assembled a talented and passionate team of IT
            professionals who are dedicated to delivering top-quality solutions
            for our clients. Our team is comprised of individuals with diverse
            backgrounds and expertise, who bring a wide range of skills to the
            table.
          </div>
          <div className="item">
            <div className="iconContainer">
              <Design width={70} height={70} fill="rgb(48, 48, 46)" />
            </div>
            Our designers are creative visionaries, with an eye for detail and a
            passion for crafting beautiful, functional interfaces. They work
            closely with our developers to ensure that every solution we create
            is not only visually stunning, but also easy to use.
          </div>
          <div className="item">
            <div className="iconContainer">
              <Asset width={70} height={70} fill="rgb(48, 48, 46)" />
            </div>
            we believe that our team is our greatest asset. We invest in our
            employees' growth and development, and encourage them to constantly
            expand their skill sets and knowledge. Our team members are not just
            experts in their respective fields – they are also collaborative,
            creative, and passionate about making a positive impact through
            technology.
          </div>
          <div className="item">
            <div className="iconContainer">
              <Developers width={70} height={70} fill="rgb(48, 48, 46)" />
            </div>
            Our developers are experienced in the latest programming languages
            and technologies, and have a deep understanding of software
            development methodologies. They are passionate about creating
            intuitive, user-friendly solutions that help businesses thrive.
          </div>
          <div className="item">
            <div className="iconContainer">
              <Cyber width={70} height={70} fill="rgb(48, 48, 46)" />
            </div>
            Our cybersecurity experts are skilled at identifying and mitigating
            potential threats, and are dedicated to ensuring that our clients'
            data is always secure. They work tirelessly to stay up-to-date on
            the latest threats and vulnerabilities, and are always ready to
            provide our clients with the guidance they need to protect their
            assets.
          </div>
          <div className="item">
            <div className="iconContainer">
              <Work width={70} height={70} fill="rgb(48, 48, 46)" />
            </div>
            When you work with {appName}, you can be confident that you are
            working with a team of dedicated professionals who are committed to
            delivering exceptional solutions and customer service.
          </div>
        </div>
      </div>
    </div>
  );
};
