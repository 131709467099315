import { Route, Routes, useNavigate } from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import { useState } from "react";
import { Button, Result } from "antd";
import { HomePage } from "./components/HomePage/HomePage";
import { AboutUS } from "./components/AboutUS/AboutUS";
import { Services } from "./components/ServicesPage/Services";
import { Products } from "./components/Products/Products";
import { Contact } from "./components/Contact/Contact";
import { QRcodeProduct } from "./components/Products/QRcodeProduct";

function App() {
  const local = window.localStorage.getItem("mode");
  const navigate = useNavigate();
  const localValue = local === "false" ? false : true;

  const [darkMode, setDarkMode] = useState(true);
  return (
    <Routes>
      <Route element={<Layout {...{ darkMode, setDarkMode }} />}>
        <Route path="/" element={<HomePage {...{ darkMode }} />} />
        <Route path="/aboutus" element={<AboutUS {...{ darkMode }} />} />
        <Route path="/services" element={<Services {...{ darkMode }} />} />
        <Route path="/products" element={<Products {...{ darkMode }} />} />
        <Route
          path="/products/qrcode"
          element={<QRcodeProduct {...{ darkMode }} />}
        />
        {/* <Route path="/portfolio" element={<Protfolio {...{ darkMode }} />} /> */}
        <Route path="/contacts" element={<Contact {...{ darkMode }} />} />
        <Route
          path="*"
          element={
            <Result
              className={darkMode ? "notFound" : "notFound-light"}
              status="403"
              title="Page not found"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Button onClick={() => navigate("/")} type="primary">
                  {" "}
                  Back Home
                </Button>
              }
            />
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
