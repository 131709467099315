import React from "react";

export const Work = ({ width, height, fill }) => {
  return (
    <svg
      version="1.1"
      height={height}
      width={width}
      fill={fill}
      id="_x32_"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 47.003 47.003"
    >
      <g>
        <g id="Layer_1_21_">
          <g>
            <path
              d="M22.267,19.763l0.598-3.85l-0.301-0.485c-0.043-0.067-0.035-0.155,0.019-0.214l0.785-0.848
                    c0.068-0.072,0.192-0.072,0.26,0l0.785,0.848c0.054,0.059,0.062,0.146,0.019,0.214l-0.301,0.485l0.598,3.849
                    c4.723-0.079,7.583-0.618,7.583-1.18c0-2.745-1.866-2.866-3.94-3.544c-1.278-0.415-2.862-0.649-2.522-2.851
                    c0.842-0.77,1.135-2.355,1.135-2.355s1.121-0.565,1.25-1.647c0.095-0.825-0.336-1.208-0.336-1.208s0-0.059,0-1.046
                    c0-2.33-1.996-4.165-4.397-4.165c-2.402,0-4.398,1.835-4.398,4.165c0,0.987,0,1.046,0,1.046s-0.43,0.383-0.335,1.208
                    c0.128,1.082,1.249,1.647,1.249,1.647s0.294,1.586,1.136,2.355c0.338,2.201-1.245,2.436-2.523,2.851
                    c-2.075,0.678-3.94,0.799-3.94,3.544C14.685,19.143,17.544,19.683,22.267,19.763z"
            />
            <path
              d="M13.682,40.513c-1.278-0.416-2.862-0.649-2.523-2.853c0.842-0.77,1.136-2.354,1.136-2.354s1.12-0.563,1.249-1.646
                    c0.095-0.826-0.335-1.209-0.335-1.209s0-0.06,0-1.045c0-2.33-1.996-4.166-4.398-4.166s-4.398,1.836-4.398,4.166
                    c0,0.986,0,1.045,0,1.045s-0.43,0.383-0.335,1.209c0.128,1.082,1.249,1.646,1.249,1.646s0.294,1.585,1.136,2.354
                    c0.338,2.202-1.245,2.437-2.523,2.853C1.865,41.189,0,41.312,0,44.056c0,0.562,2.859,1.103,7.583,1.182l0.598-3.85L7.88,40.902
                    c-0.043-0.068-0.035-0.154,0.019-0.213l0.785-0.85c0.068-0.07,0.192-0.07,0.26,0l0.785,0.85c0.054,0.059,0.062,0.146,0.019,0.213
                    l-0.301,0.485l0.598,3.849c4.723-0.078,7.583-0.619,7.583-1.181C17.622,41.312,15.756,41.189,13.682,40.513z"
            />
            <path
              d="M43.057,40.513c-1.277-0.416-2.861-0.649-2.522-2.853c0.842-0.77,1.136-2.354,1.136-2.354s1.12-0.563,1.249-1.646
                    c0.095-0.826-0.335-1.209-0.335-1.209s0-0.06,0-1.045c0-2.33-1.996-4.166-4.398-4.166c-2.401,0-4.397,1.836-4.397,4.166
                    c0,0.986,0,1.045,0,1.045s-0.431,0.383-0.335,1.209c0.128,1.082,1.249,1.646,1.249,1.646s0.294,1.585,1.136,2.354
                    c0.338,2.202-1.244,2.437-2.523,2.853c-2.074,0.677-3.939,0.799-3.939,3.543c0,0.562,2.859,1.103,7.583,1.182l0.599-3.85
                    l-0.302-0.485c-0.043-0.068-0.034-0.154,0.019-0.213l0.785-0.85c0.068-0.07,0.192-0.07,0.26,0l0.785,0.85
                    c0.055,0.059,0.062,0.146,0.02,0.213l-0.301,0.485l0.598,3.849c4.723-0.078,7.583-0.619,7.583-1.181
                    C46.997,41.312,45.131,41.189,43.057,40.513z"
            />
            <path
              d="M30.497,36.326c0.337,0,0.671-0.145,0.903-0.424c0.416-0.499,0.348-1.24-0.151-1.654l-6.577-5.474v-6.449
                    c0-0.649-0.526-1.175-1.175-1.175s-1.175,0.526-1.175,1.175v6.449l-6.577,5.474c-0.499,0.414-0.567,1.155-0.152,1.654
                    s1.156,0.566,1.655,0.15l6.249-5.199l6.249,5.199C29.965,36.236,30.23,36.326,30.497,36.326z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
