import { Button, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { useState } from "react";
import { serviceID, templateID, userID } from "../../env.js";
import { Email } from "../../Assets/Icons/Email";
import { Linkedin } from "../../Assets/Icons/Linkedin";
import { appName, email, emailHref, linkedinHref } from "../Helpers/Constants";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./Contacts.scss";

export const Contact = ({ header }) => {
  const [focus, setFocus] = useState(null);
  const [form] = Form.useForm();

  const toastifySuccess = () => {
    toast("Thank You  We will Contact You as soon as possible!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const toastifySuccessError = () => {
    toast("Try Again later please!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback dangger",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    const templateParams = {
      username: data?.username,
      email: data?.email,
      message: data?.message,
    };
    try {
      await emailjs.send(serviceID, templateID, templateParams, userID);
      form.resetFields();
      toastifySuccess();
    } catch (e) {
      form.resetFields();
      toastifySuccessError();
    }
  };

  const headerStyle = {
    background: "unset",
    height: 100,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contacts">
      <div className="HeaderContact" style={header && headerStyle}>
        {/* <a href={phoneCall}>
          <div className="item">
            <div className="icon">
              <WhatsApp width={35} height={35} fill="#000" />
            </div>
            <div className="title">Whats'app</div>
            <div className="des">{whatsApp}</div>
          </div>
        </a> */}
        <a href={emailHref}>
          <div className="item">
            <div className="icon">
              <Email width={35} height={35} fill="#000" />
            </div>
            <div className="title">EMAIL</div>
            <div className="des">{email}</div>
          </div>
        </a>

        <a href={linkedinHref} rel="" target="_blank">
          <div className="item">
            <div className="icon">
              <Linkedin width={35} height={35} fill="#000" />
            </div>
            <div className="title">Linkedin</div>
            <div className="des">{appName}</div>
          </div>
        </a>
      </div>
      <div className="contactForm">
        <div className="descriptionCon">
          <div className="title">Contact {appName}</div>
          <div className="des">
            If you're interested in our services and would like to learn more,
            please don't hesitate to get in touch. We offer a wide range of IT
            solutions, including software development, web design, and IT
            consulting, and we're always happy to discuss your specific needs
            and how we can help.
          </div>
        </div>
        <div className="formCon">
          <Form onFinish={onSubmit} form={form} name="contacts">
            <div className="inputContainer">
              <div className="label">Full Name</div>
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your Full Name!" },
                ]}
              >
                <Input
                  onBlur={() => setFocus(null)}
                  onFocus={() => setFocus(1)}
                />
              </Form.Item>
              <div className={focus === 1 ? "lineActive" : "line"} />
            </div>
            <div className="inputContainer">
              <div className="label">Email</div>
              <Form.Item
                name="email"
                type="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  type="email"
                  onBlur={() => setFocus(null)}
                  onFocus={() => setFocus(2)}
                />
              </Form.Item>
              <div className={focus === 2 ? "lineActive" : "line"} />
            </div>
            <div className="inputContainer">
              <div className="label">Message</div>
              <Form.Item
                name="message"
                rules={[
                  { required: true, message: "Please input your message!" },
                ]}
              >
                <TextArea
                  rows={4}
                  onBlur={() => setFocus(null)}
                  onFocus={() => setFocus(3)}
                />
              </Form.Item>
              <div className={focus === 3 ? "lineActive" : "line"} />
            </div>

            <Form.Item>
              <Button className="contactBtn" htmlType="submit">
                Contact US
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
