import React from "react";

export const LandingSection = ({ imgRef }) => {
  return (
    <div className="firstSection">
      <div
        className="background"
        style={{ background: `url(${imgRef})`, backgroundPosition: "center" }}
      >
        <div className="descriptionSection">
          <div className="item">
            <div className="it">IT</div>
            <div className="services">Services</div>
          </div>
        </div>
        <div className="descriptionSecond">
          <div className="item">
            <div className="it">INOVATION</div>
            <div className="services">It's Here</div>
          </div>
        </div>
      </div>
    </div>
  );
};
