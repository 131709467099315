import { Steps } from "antd";
import React, { useState } from "react";
import { StepperBG, StepperBG1 } from "../../../Assets/Images";

export const ProductSection = () => {
  const [current, setCurrent] = useState({ value: 0, percent: 15 });
  const onChange = (value) => {
    if (value === 0) {
      setCurrent({ value: 0, percent: 15 });
    }
    if (value === 1) {
      setCurrent({ value: 1, percent: 30 });
    }
    if (value === 2) {
      setCurrent({ value: 2, percent: 40 });
    }
    if (value === 3) {
      setCurrent({ value: 3, percent: 50 });
    }
    if (value === 4) {
      setCurrent({ value: 4, percent: 65 });
    }
    if (value === 5) {
      setCurrent({ value: 5, percent: 80 });
    }
    if (value === 6) {
      setCurrent({ value: 6, percent: 100 });
    }
  };
  const descriptions = () => {
    switch (current.value) {
      case 0:
        return "The first step is to define the project scope, including the project goals, requirements, timelines, and budget. This will help to ensure that all stakeholders are aligned on the project objectives and expectations.";
      case 1:
        return "Once the project scope is defined, the project team can begin to plan the project. This will involve developing a project plan, identifying project tasks, and estimating the resources required to complete the project.";
      case 2:
        return "Based on the project plan, the technical team can begin to develop technical specifications that outline the technical requirements of the project. This will include software development frameworks, database design, user interface design, and other technical specifications.";
      case 3:
        return "With the technical specifications in place, the development team can begin developing the software. This will involve designing and coding the software, testing the software, and addressing any bugs or issues that arise during the development process.";
      case 4:
        return "Once the software development is complete, the team will conduct testing to ensure that the software is functioning as expected. This will include unit testing, integration testing, and user acceptance testing.";
      case 5:
        return " Once the software is tested and approved, the team can deploy the software to the production environment. This will involve configuring the software, installing it on servers, and ensuring that it is running smoothly.";
      case 6:
        return "After the software is deployed, the team will provide ongoing support and maintenance to ensure that the software is functioning as expected. This will include addressing any issues that arise, updating the software as needed, and providing training to end-users.";
      default:
        return "test";
    }
  };
  return (
    <div className="stepper">
      <div className="steepDiv">
        <Steps
          direction="vertical"
          onChange={onChange}
          current={current?.value}
          percent={current?.percent}
          className="site-navigation-steps"
          items={[
            {
              title: "Define Project Scope",
            },
            {
              title: "Plan the Project",
            },
            {
              title: "Develop Technical Specifications",
            },
            {
              title: "Develop the Software",
            },
            {
              title: "Test the Software",
            },
            {
              title: "Deploy the Software",
            },
            {
              title: "Provide Support and Maintenance",
            },
          ]}
        />
      </div>
      <div className="stepperContent">
        <div className="content">{descriptions()}</div>
      </div>
      <div className="imgDiv"></div>
    </div>
  );
};
