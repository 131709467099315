import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Drawer } from "antd";
import { Logo } from "../../Assets/Images";
import { Menu } from "../../Assets/Icons/Menu";

export const NavBar = ({ darkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  const [menuModal, setMenuModal] = useState(false);

  const navigation = () => (
    <div className={darkMode ? "navigationDark" : "naviigationLight"}>
      <div
        className={path === "/" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/");
          setMenuModal(false);
        }}
      >
        Home
      </div>
      <div
        className={path === "/aboutus" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/aboutus");
          setMenuModal(false);
        }}
      >
        About Us
      </div>
      <div
        className={path === "/services" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/services");
          setMenuModal(false);
        }}
      >
        Services
      </div>
      <div
        className={
          path === "/products" || path === "/products/qrcode"
            ? "nav-item active"
            : "nav-item"
        }
        onClick={() => {
          navigate("/products");
          setMenuModal(false);
        }}
      >
        Products
      </div>

      {/* <div
        className={path === "/portfolio" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/portfolio");
          setMenuModal(false);
        }}
      >
        Portfolio
      </div> */}

      <div
        className={path === "/contacts" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/contacts");
          setMenuModal(false);
        }}
      >
        Contacts
      </div>
    </div>
  );

  return (
    <div className={darkMode ? "nav-dark" : "nav-light"}>
      <div className="logo-contanier" onClick={() => navigate("/")}>
        <img src={Logo} alt="logo" />
      </div>
      {isTabletOrMobile ? (
        <div className="mobile-icon-container">
          <div
            onClick={() => setMenuModal(true)}
            className="hamburger-contanier"
          >
            <Menu fill={"#d2691e"} />
          </div>
          {menuModal && (
            <Drawer
              className={darkMode ? "dark-drawer" : "light-drawer"}
              title="Navigation Menu"
              placement="right"
              onClose={() => setMenuModal(false)}
              width={"100%"}
              open={menuModal}
            >
              {navigation()}
            </Drawer>
          )}
        </div>
      ) : (
        <>
          {navigation()}
          <div></div>
        </>
      )}
    </div>
  );
};
