import { useEffect, useState } from "react";

export const useLoadingImage = (SectionImg) => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = SectionImg;

    const handleBackgroundLoaded = () => {
      setBackgroundLoaded(true);
    };

    const handleBackgroundError = () => {
      setBackgroundLoaded(false);
    };

    backgroundImage.addEventListener("load", handleBackgroundLoaded);
    backgroundImage.addEventListener("error", handleBackgroundError);

    return () => {
      backgroundImage.removeEventListener("load", handleBackgroundLoaded);
      backgroundImage.removeEventListener("error", handleBackgroundError);
    };
  }, [backgroundLoaded, SectionImg]);

  return { backgroundLoaded };
};
