import React from "react";
import { useOutlet } from "react-router-dom";
import { NavBar } from "./NavBar";
import Footer from "./Footer";
import "./layout.scss";

export const Layout = ({ darkMode, setDarkMode }) => {
  const outlet = useOutlet();
  return (
    <>
      <NavBar {...{ darkMode, setDarkMode }} />
      {outlet}
      <Footer {...{ darkMode, setDarkMode }} />
    </>
  );
};
